<template>
  <div id="address-information">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="6" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <br />
            <v-skeleton-loader v-bind="attrs" type="paragraph"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card elevation="4" outlined shaped v-if="!StartupLoadingFlag" id="v-card">
      <br />
      <v-row wrap>
        <v-col cols="12" lg="12">
          <h3 class="text-dark lg-ml-3 ml-3">
            <!-- <i id="app-bar-icon" class="fas fa-users"></i>&nbsp;&nbsp; -->
            <b id="heading">Training Career</b>
          </h3>
        </v-col>
      </v-row>

      <v-card-text style="padding-left: 1%; padding-right: 0%">
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap id="card-text">
            <v-col
              cols="12"
              lg="6"
              md="12"
              sm="12"
              v-for="item in tableData1"
              :key="item.Id"
            >
              <v-card class="p-4">
                <v-row wrap>
                  <v-col cols="12" lg="12" md="12" sm="12">
                    <viewer rebuild :images="item.AllImages" class="text-center">
                      <img
                        style="padding: 5px"
                        width="80"
                        height="80"
                        :src="src"
                        :key="src"
                        :alt="item.TrainerTypeName"
                        v-for="src in item.AllImages"
                      />
                    </viewer>
                    <br />
                    <h6>
                      Status :
                      <v-chip :color="getActiveStatusColor(item.ActiveStatusTxt)" small>
                        <span>{{ item.ActiveStatusTxt }}</span>
                      </v-chip>
                    </h6>
                    <h6>
                      Date :
                      {{ item.DateTxt }}
                    </h6>
                    <h6>
                      Type :
                      {{ item.TrainerTypeName }}
                    </h6>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},

  data() {
    return {
      StartupLoadingFlag: false,
      MemberId: 0,
      rows: {},

      pageTitle: "Training Career",
      pageDescription: "List of training career details",

      DeleteLoadingFlag: false,

      SearchFlag: false,
      ResultFlag: false,

      selected: [],
      selectedData: {},
      search: "",

      FloatingButton: {},

      tableColumns1: [],
      tableOptions1: [],
      tableData1: [],

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");

      this.MemberId = this.$route.query.id;
      console.log("MemberId =", this.MemberId);

      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords is called");

      // var validate1 = this.$refs.form2.validate();
      var validate1 = true;
      console.log("validate1=" + validate1);

      if (validate1) {
        this.StartupLoadingFlag = true;
        this.SearchFlag = true;
        this.ResultFlag = false;
        this.tableData1 = [];
        this.selected = [];
        this.selectedData = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/trainer-details/lists";
        var upload = {
          UserInterface: 1,
          MemberId: this.MemberId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.StartupLoadingFlag = false;
            thisIns.SearchFlag = false;
            thisIns.ResultFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            thisIns.tableOptions1 = records.TableOptions;
            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableData1 = records.TableData;
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
            thisIns.StartupLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message, false);
      }
    },
  },
  mounted() {},
  beforeMount() {
    console.log("beforeMount called");
    this.refreshPageData();
  },
};
</script>
