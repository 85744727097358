<template>
  <div id="family-nominee">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="6" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <br />
            <v-skeleton-loader v-bind="attrs" type="paragraph"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card elevation="4" outlined shaped v-if="!StartupLoadingFlag" id="v-card">
      <br />

      <v-row wrap>
        <v-col cols="9" lg="9">
          <h3 class="text-dark lg-ml-3 ml-3">
            <!-- <i id="app-bar-icon" class="fas fa-hospital-user"></i>&nbsp;&nbsp; -->
            <b id="heading">Nominee Details</b>
          </h3>
        </v-col>
      </v-row>

      <v-card-text style="padding-left: 1%; padding-right: 0%" id="card-text">
        <v-container v-if="tableData1 == ''">
          <h6 class="text-center">NA.</h6>
        </v-container>
        <v-container class="py-0" v-else>
          <v-row>
            <v-col cols="12" sm="4" md="6" lg="3">
              <h6>
                Relation
                <br />
                <span class="text-dark"> {{ tableData1.Relationship }} </span>
              </h6>
            </v-col>
            <v-col cols="12" sm="4" md="6" lg="3">
              <h6>
                Name
                <br />
                <span class="text-dark">
                  {{ tableData1.RelativeName }}
                </span>
              </h6>
            </v-col>
            <v-col cols="12" sm="4" md="6" lg="3">
              <h6>
                Mobile No
                <br />
                <span class="text-dark">
                  {{ tableData1.RelativeMobileNo }}
                </span>
              </h6>
            </v-col>
            <v-col cols="12" sm="4" md="6" lg="3">
              <h6>
                Membership Id
                <br />
                <span class="text-dark">
                  {{ tableData1.RelativeMembershipId }}
                </span>
              </h6>
            </v-col>
            <v-col cols="12" sm="4" md="6" lg="12">
              <h6>
                Address
                <br />
                <span class="text-dark">
                  {{ tableData1.RelativeAddress }}
                  <br />
                  {{ tableData1.RelativeStateName }},
                  {{ tableData1.RelativeDistrictName }},
                  {{ tableData1.RelativeCityName }} -
                  {{ tableData1.RelativePincode }}
                </span>
              </h6>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import Swal from "sweetalert2";

export default {
  components: {},

  data() {
    return {
      StartupLoadingFlag: false,
      MemberId: 0,

      pageTitle: "",
      pageDescription: "",

      DeleteLoadingFlag: false,

      SearchFlag: false,
      ResultFlag: false,

      selected: [],
      selectedData: {},
      search: "",

      FloatingButton: {},

      tableColumns1: [],
      tableOptions1: {
        FloatingButton: {},
      },
      tableData1: [],

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");

      this.MemberId = this.$route.query.id;
      console.log("MemberId =", this.MemberId);

      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords is called");

      // var validate1 = this.$refs.form2.validate();
      var validate1 = true;
      console.log("validate1=" + validate1);

      if (validate1) {
        this.SearchFlag = true;
        this.StartupLoadingFlag = true;
        this.ResultFlag = false;
        this.tableData1 = [];
        this.selected = [];
        this.selectedData = {};

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members-family-details/nominee-details";
        var upload = {
          UserInterface: 1,
          MemberId: this.MemberId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;
            thisIns.StartupLoadingFlag = false;
            thisIns.ResultFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableOptions1 = records.TableOptions;

            if (flag == 1) {
              // thisIns.toast("success", output);
              thisIns.tableData1 = records.TableData;
            } else {
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
            thisIns.StartupLoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message, false);
      }
    },
  },

  mounted() {},
  beforeMount() {
    console.log("beforeMount called");

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    if (token == 0) {
      this.$router.push("/login");
    } else {
      this.UserFlag = true;
      this.refreshPageData();
    }
  },
};
</script>
