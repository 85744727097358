<template>
  <div id="address-information">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="6" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <br />
            <v-skeleton-loader v-bind="attrs" type="paragraph"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card elevation="4" outlined shaped v-if="!StartupLoadingFlag" id="v-card" class="p-2">
      <br />
      <v-row wrap>
        <v-col cols="12">
          <h3 class="text-dark lg-ml-3 ml-3">
            <!-- <i id="app-bar-icon" class="fas fa-hand-holding-heart"></i>&nbsp;&nbsp; -->
            <b id="heading">JCI India Foundation Contribution</b>
          </h3>
        </v-col>
      </v-row>

      <v-card-text style="padding-left: 1%; padding-right: 0%" id="card-text">
        <!-- <v-container class="py-0" v-if="titles.length > 0">
          <h6 v-if="NotesFlag">
            <span class="text-danger">*</span> Note :
            <span class="text-dark">Data shall be shown only from the year 2021 onwards.</span>
          </h6>
          <br />
        </v-container> -->
        <v-row>
          <v-col cols="12" xl="4" md="6" sm="12" v-for="(item, index) in titles" :key="index">
            <v-card v-if="titles.length > 0" class="p-3">
              <v-row wrap>
                <v-col cols="12" xl="12" md="12" sm="12">
                  <h5> Title : {{ item.TitleName }}</h5>
                  <h5> Paid Date : {{ item.PaidDate }}</h5>
                  <h5> Receipt No : {{ item.ReceiptNo }}</h5>
                  <h5> Transaction Type : {{ item.TransactionTypeTxt }}</h5>
                </v-col>
                <v-col cols="12" xl="12" md="12" sm="12" align="right">
                  <v-btn v-if="item.ReceiptPath == '' || item.TransactionTypeId == 2" small
                    style="border: none; background: none; outline: none;">
                    <span style="font-size: small">NA</span>
                  </v-btn>
                  <v-btn v-else :href="item.ReceiptPath" target="_blank" small v-bind="attrs" v-on="on">
                    <v-icon dark color="blue" medium> mdi-download </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    titles: {
      required: true,
    },
  },

  data() {
    return {
      StartupLoadingFlag: false,
      MemberId: 0,

      selected: [],
      search: "",
      rows: [],

      NotesFlag: false,
    };
  },
  computed: {},
  watch: {
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      console.log("titles = ", this.titles);

      // this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");
      this.StartupLoadingFlag = true;
      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/show";
      var upload = {
        UserInterface: 1,
        MemberId: this.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          thisIns.StartupLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records[0];
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.rows = records;
            console.log(thisIns.rows);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          this.StartupLoadingFlag = false;
          this.toast("error", output);
        });
    },
  },
  mounted() { },
  beforeMount() {
    console.log("beforeMount called");
    this.refreshPageData();
  },
};
</script>