<template>
  <div id="address-information">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="6" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <br />
            <v-skeleton-loader v-bind="attrs" type="paragraph"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card elevation="4" outlined shaped v-if="!StartupLoadingFlag" id="v-card">
      <br />
      <v-row wrap>
        <v-col cols="12" lg="12">
          <h3 class="text-dark lg-ml-3 ml-3">
            <!-- <i id="app-bar-icon" class="fas fa-house-user"></i>&nbsp;&nbsp; -->
            <b id="heading">Address Information</b>
          </h3>
        </v-col>
      </v-row>

      <v-card-text style="padding-left: 5%; padding-right: 0%" id="card-text">
        <v-container class="py-0 mb-4">
          <v-row wrap>
            <v-col cols="12" sm="4" md="6" lg="3">
              <h6>Street1</h6>
              <h6 class="text-dark">
                {{ rows.Street1 }}
              </h6>
            </v-col>
            <v-col cols="12" sm="4" md="6" lg="3">
              <h6>Street2</h6>
              <h6 class="text-dark">
                {{ rows.Street2 }}
              </h6>
            </v-col>
            <v-col cols="12" sm="4" md="6" lg="3">
              <h6>Area</h6>
              <h6 class="text-dark">
                {{ rows.Area }}
              </h6>
            </v-col>
            <v-col cols="12" sm="4" md="6" lg="3">
              <h6>City</h6>
              <h6 class="text-dark">
                {{ rows.CityName }}
              </h6>
            </v-col>
            <v-col cols="12" sm="4" md="6" lg="3">
              <h6>District</h6>
              <h6 class="text-dark">
                {{ rows.DistrictName }}
              </h6>
            </v-col>
            <v-col cols="12" sm="4" md="6" lg="3">
              <h6>State</h6>
              <h6 class="text-dark">
                {{ rows.StateName }}
              </h6>
            </v-col>
            <v-col cols="12" sm="4" md="6" lg="3">
              <h6>Pincode</h6>
              <h6 class="text-dark">
                {{ rows.Pincode }}
              </h6>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},

  data() {
    return {
      StartupLoadingFlag: false,
      MemberId: 0,
      rows: {},
      UserFlag: false,
      addRecordPrompt: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");

      this.MemberId = this.$route.query.id;
      console.log("MemberId = ", this.MemberId);

      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");
      this.StartupLoadingFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/show";
      var upload = {
        UserInterface: 1,
        MemberId: this.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          thisIns.StartupLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records[0];
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.rows = records;
            console.log(thisIns.rows);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          this.StartupLoadingFlag = false;
        });
    },
  },
  mounted() {},
  beforeMount() {
    console.log("beforeMount called");

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    if (token == 0) {
      this.$router.push("/login");
    } else {
      this.UserFlag = true;
      this.refreshPageData();
    }
  },
};
</script>
