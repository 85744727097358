<template>
  <div id="personal-information">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="4" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <br />
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="4"
      outlined
      shaped
      v-if="!StartupLoadingFlag"
      id="v-card"
    >
      <v-card-text id="card-text">
        <v-row wrap>
          <v-col cols="12" sm="12" md="12" lg="3" xl="3">
            <div class="d-flex justify-content-center">
              <img
                :src="rows.MemberImagePath"
                :alt="rows.MemberName"
                lazy-src="/no-image-available.png"
                width="100px"
                height="100px"
              />
            </div>
            <div class="d-flex justify-content-center mt-6">
              <img
                v-if="rows.MemberQrCodeFlag"
                :src="rows.MemberQrImagePath"
                :alt="rows.MemberName"
                lazy-src="/no-image-available.png"
                width="100px"
                height="100px"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="9" xl="6" id="card-text">
            <br />
            <div class="d-flex flex-wrap mb-4">
              <p
                class="text-dark-65 font-size-h6 font-weight-bold mr-lg-8 mr-4 mb-lg-0 mb-2"
              >
                <a
                  href="#"
                  class="text-dark font-size-h6 font-weight-bold mr-3"
                >
                  {{ rows.MemberName }} &nbsp;
                  <i class="flaticon2-correct text-success font-size-h6"></i>
                </a>
              </p>
              <p
                class="text-dark font-size-h6 font-weight-bold mr-lg-8 mr-4 mb-lg-0 mb-2"
              >
                <i class="flaticon2-calendar-3 mr-1"></i>
                {{ rows.MobileNo }} &nbsp;
                <i class="font-size-h6" v-bind:class="rows.MobileNoIcon"></i>
              </p>
              <p
                class="text-dark font-size-h6 font-weight-bold mr-lg-8 mr-4 mb-lg-0 mb-2"
              >
                <i class="flaticon2-new-email mr-1"></i>
                {{ rows.EmailId }} &nbsp;
                <i class="font-size-h6" v-bind:class="rows.EmailIdIcon"></i>
              </p>
            </div>
            <v-row wrap>
              <v-col cols="12" sm="4" md="6" lg="3" xl="3">
                <h6 class="text-dark-50">Membership Id</h6>
                <h6 class="text-dark">{{ rows.MembershipId }}</h6>
              </v-col>
              <v-col cols="12" sm="4" md="6" lg="3" xl="3">
                <h6 class="text-dark-50">Date Of Birth</h6>
                <h6 class="text-dark">{{ rows.DOB }}</h6>
              </v-col>
              <v-col cols="12" sm="4" md="6" lg="2" xl="3">
                <h6 class="text-dark-50">Gender</h6>
                <h6 class="text-dark">{{ rows.GenderTxt }}</h6>
              </v-col>
              <v-col cols="12" sm="4" md="6" lg="3" xl="3">
                <h6 class="text-dark-50">Martial Status</h6>
                <h6 class="text-dark">{{ rows.MartialStatusTxt }}</h6>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="6"
                lg="3"
                xl="3"
                v-if="rows.MartialStatus == 1"
              >
                <h6 class="text-dark-50">Anniversary Date</h6>
                <h6 class="text-dark">{{ rows.AnniversaryDate }}</h6>
              </v-col>
              <v-col cols="12" sm="4" md="6" lg="3" xl="3">
                <h6 class="text-dark-50">Blood Group</h6>
                <h6 class="text-dark">{{ rows.BloodGroupTxt }}</h6>
              </v-col>
              <v-col cols="12" sm="4" md="6" lg="4" xl="4">
                <h6 class="text-dark-50">
                  Are you interested to donate blood?
                </h6>
                <h6 class="text-dark">
                  {{ rows.DonateBloodTxt }}
                </h6>
              </v-col>
              <v-col cols="12" sm="8" md="6" lg="12" xl="4">
                <h6 class="text-dark-50">About Me</h6>
                <h6 class="text-dark">
                  {{ rows.AboutMe }}
                </h6>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";

export default {
  mixins: [common],
  components: {},

  data() {
    return {
      StartupLoadingFlag: false,
      MemberId: 0,
      rows: {},

      default_photo: "media/users/blank.png",
      current_photo: null,

      UserFlag: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");

      this.MemberId = this.$route.query.id;
      console.log("MemberId =", this.MemberId);

      this.getTableRecords();
    },
    getTableRecords() {
      console.log("getTableRecords called");
      this.StartupLoadingFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/show";
      var upload = {
        UserInterface: 1,
        MemberId: this.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          thisIns.StartupLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records[0];
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.rows = records;
            console.log(thisIns.rows);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          this.StartupLoadingFlag = false;
        });
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  beforeMount() {
    console.log("beforeMount called");

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    if (token == 0) {
      this.$router.push("/login");
    } else {
      this.UserFlag = true;
      this.refreshPageData();
    }
  },
};
</script>
